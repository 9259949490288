<div class="container-fluid top-buffer">
	<div class="row">
		<div class="col-md-12">
			<div class="cms-page-header">
				<h2>Migration History</h2>
				<div class="form-inline">
					<div class="form-group">
						<label for="statusFilter" style="margin-right: 10px;">Status:</label>
						<select id="statusFilter" class="form-control">
							<option value="">All</option>
							<option value="completed">Completed</option>
							<option value="failed">Failed</option>
							<option value="active">Active</option>
							<option value="waiting">Waiting</option>
						</select>
					</div>
					<button id="create-new-migration" class="button button--shikoba button--primary" style="margin-left: 15px;">
						<i class="fa fa-plus"></i>
						<span>New Migration</span>
					</button>
				</div>
			</div>
			<div class="row" style="margin-bottom: 15px;">
				<div class="col-md-2">
					<div class="stat-box {{#unless stats.completed}}disabled{{/unless}}" data-status="completed">
						<div class="stat-label">Completed</div>
						<div class="stat-value text-success">{{stats.completed}}</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="stat-box {{#unless stats.active}}disabled{{/unless}}" data-status="active">
						<div class="stat-label">Active</div>
						<div class="stat-value text-primary">{{stats.active}}</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="stat-box {{#unless stats.failed}}disabled{{/unless}}" data-status="failed">
						<div class="stat-label">Interrupted</div>
						<div class="stat-value text-danger">{{stats.failed}}</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="stat-box {{#unless stats.waiting}}disabled{{/unless}}" data-status="waiting">
						<div class="stat-label">Waiting</div>
						<div class="stat-value text-warning">{{stats.waiting}}</div>
					</div>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-hover">
					<thead>
						<tr>
							<th>ID</th>
							<th>Status</th>
							<th>Content</th>
							<th>Migration IDs</th>
							<th>User</th>
							<th>Started</th>
							<th>Finished</th>
							<th>Duration</th>
							<th class="text-right">Actions</th>
						</tr>
					</thead>
					<tbody>
						{{#each jobs}}
						<tr>
							<td><code>{{id}}</code></td>
							<td>
								<span class="status-badge status-{{state}}">{{state}}</span>
							</td>
							<td>
								{{#if data.contentIdList}}
								<div class="content-info">
									<code>{{data.contentIdList.[0]}}</code>
									{{#if contentCount}}
									<code>+{{contentCount}}</code>
									{{/if}}
								</div>
								{{/if}}
							</td>
							<td>
								{{#if data.migrationIds}}
								<div class="content-info">
									<code>{{data.migrationIds.[0]}}</code>
									{{#if migrationCount}}
									<code>+{{migrationCount}}</code>
									{{/if}}
								</div>
								{{/if}}
							</td>
							<td>{{user}}</td>
							<td>{{processedOnFormatted}}</td>
							<td>{{finishedOnFormatted}}</td>
							<td>{{duration}}</td>
							<td class="text-right">
								<div class="btn-group btn-group-sm">
									<button type="button" class="btn btn-info view-details" data-id="{{id}}" title="View Details">
										<i class="fa fa-eye"></i>
									</button>
									{{#if hasFailed}}
									<button type="button" class="btn btn-warning retry-job" data-id="{{id}}" title="Retry Job">
										<i class="fa fa-refresh"></i>
									</button>
									{{/if}}
									<button type="button" class="btn btn-danger remove-job" data-id="{{id}}" title="Remove Job">
										<i class="fa fa-trash"></i>
									</button>
								</div>
							</td>
						</tr>
						{{/each}}
					</tbody>
				</table>
			</div>

			{{#if pages.length}}
			<nav class="text-center" style="margin-top: 20px;position: sticky; bottom:0">
				<ul class="pagination">
					{{#if showPrevious}}
					<li class="page-item">
						<a class="page-link previous-page" href="#" aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
						</a>
					</li>
					{{/if}}

					{{#each pages}}
					<li class="page-item {{#if active}}active{{/if}}">
						<a class="page-link page-number" href="#" data-page="{{number}}">{{number}}</a>
					</li>
					{{/each}}

					{{#if showNext}}
					<li class="page-item">
						<a class="page-link next-page" href="#" aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
						</a>
					</li>
					{{/if}}
				</ul>
			</nav>
			{{/if}}
		</div>
	</div>
</div>

<!-- Job Details Modal -->
<div class="modal fade" id="jobDetailsModal" tabindex="-1" role="dialog">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Migration Details</h4>
				<button type="button" class="cms-button-close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="job-details-content"></div>
			</div>
		</div>
	</div>
</div>

<style>
	.cms-page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;
		padding: 1rem 0;
		border-bottom: 1px solid #dee2e6;
	}

	.cms-page-header h2 {
		margin: 0;
		font-size: 1.75rem;
	}

	.stat-box {
		background: white;
		border: 1px solid #dee2e6;
		border-radius: 4px;
		padding: 1rem;
		text-align: center;
		transition: all 0.2s;
		cursor: pointer;
	}

	.stat-box:not(.disabled):hover {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		transform: translateY(-1px);
	}

	.stat-box.disabled {
		opacity: 0.6;
		cursor: not-allowed;
		pointer-events: none;
	}

	.stat-box.active {
		border-color: #007bff;
		background-color: rgba(0, 123, 255, 0.05);
	}

	.stat-label {
		font-size: 0.875rem;
		font-weight: 600;
		text-transform: uppercase;
		color: #6c757d;
		margin-bottom: 0.5rem;
	}

	.stat-value {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.badge {
		display: inline-block;
		padding: 0.35em 0.65em;
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
		text-transform: uppercase;
	}

	.badge.badge-light {
		background-color: #f8f9fa;
		color: #495057;
		border: 1px solid #dee2e6;
	}

	.content-info {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	.status-completed {
		background: #28a745;
		color: white;
	}

	.status-failed {
		background: #dc3545;
		color: white;
	}

	.status-active {
		background: #007bff;
		color: white;
	}

	.status-waiting {
		background: #ffc107;
		color: black;
	}

	.status-paused {
		background: #17a2b8;
		color: white;
	}

	.btn-group {
		white-space: nowrap;
	}

	.btn-group .btn {
		float: none;
		display: inline-block;
	}

	.status-badge {
		padding: 5px 10px;
		border-radius: 3px;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.table td {
		vertical-align: middle;
	}

	.top-buffer {
		padding-top: 15px;
	}
</style>